import React from 'react'
import styled, { css } from 'styled-components'
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Link as A,
  Text,
  hexa,
  theme
} from '@hackclub/design-system'
import { Link } from 'gatsby'
import PureImagination from 'components/PureImagination'

const Base = styled(Box.withComponent('footer'))`
  background: ${(props) =>
    props.dark
      ? `${theme.colors.darker} radial-gradient(${hexa(
          theme.colors.black,
          0.5
        )} 1px, transparent 1px)`
      : ``};
  ${(props) =>
    props.dark &&
    css`
      background-size: ${theme.space[4]}px ${theme.space[4]}px;
      h2 {
        color: ${theme.colors.gray[4]};
      }
      ${BottomLine} {
        border-color: ${theme.colors.black};
      }
    `} @media print {
    display: none;
  }
`

const Columns = styled(Container)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${theme.space[4]}px;
  ${theme.mediaQueries.md} {
    grid-gap: ${theme.space[4]}px;
    grid-template-columns: repeat(3, 1fr);
  }
  iframe {
    border-radius: 10px;
    @media (max-width: 426px) {
      margin-left: 1.2rem;
    }
    @media (max-width: 376px) {
      width: 300px;
      margin-left: 1rem;
    }
    @media (max-width: 321px) {
      width: 270px;
    }
  }
`

const Services = styled(Flex)`
  a {
    line-height: 0;
  }
  svg {
    fill: currentColor;
    width: 32px;
    height: 32px;
    transition: 0.5s;
    &:hover {
      transform: scale(1.2);
    }
  }
  ${theme.mediaQueries.md} {
    max-width: 12rem;
  }
`
Services.defaultProps = {
  align: 'center',
  mb: 3,
  wrap: true
}

const Service = ({ href, icon, ...props }) => (
  <A
    target="_blank"
    rel="noopener"
    href={href}
    mr={2}
    mb={2}
    color="muted"
    aria-label={`Coding Sastra on ${icon}`}
    children={<Icon glyph={icon} />}
    {...props}
  />
)

const Pages = styled(Box)`
  a {
    display: block;
    color: inherit;
    margin-bottom: ${theme.space[2]}px;
  }
`

const BottomLine = styled(Box)`
  border-top: 1px solid ${theme.colors.smoke};
`

const Footer = ({ dark = false, children, ...props }) => (
  <Base
    color={dark ? 'muted' : 'slate'}
    py={[4, 5, 6]}
    dark={dark}
    align="left"
    {...props}
  >
    {children}
    <PureImagination />
    <Columns px={4}>
      <Box>
        <Heading.h2 fontSize={3} mb={3}>
          Coding Sastra
        </Heading.h2>
        <Text>
          <strong>Hyderabad</strong> <br />
          HIG-129, Pramani Plaza, 5th Floor <br />
          Phase 1&2 Kukatpally
          <br />
          KPHB, Hyderabad, TS 500082
          <br />
          <br />
          <strong>Phone:</strong>
          <br />
          +91 9951013134 (Whatsapp) <br />
          <br />
          <strong>E-Mail:</strong>
          <br />
          <Service href="mailto:contact@codingsastra.com">
            contact@codingsastra.com
          </Service>{' '}
        </Text>
        <br />
        <Heading.h2 fontSize={3} mb={3}>
          Follow Us
        </Heading.h2>
        <Services>
          <Service
            href="https://www.facebook.com/codingsastra/"
            icon="facebook"
            style={{ color: '#3b5998' }}
          />
          <Service
            href="https://twitter.com/codingsastra"
            icon="twitter"
            style={{ color: '#00acee' }}
          />
          <Service
            href="https://www.youtube.com/c/codingsastra"
            icon="youtube"
            style={{ color: '#c4302b' }}
          />
          <Service href="mailto:contact@codingsastra.com" icon="email-fill" />
        </Services>
      </Box>
      <Box>
        <Heading.h2 fontSize={3} mb={3}>
          Links
        </Heading.h2>
        <Pages>
          <Link to="/about/" children="About" />
          <Link to="/faq" children="FAQ" />
          <Link to="/contact/" children="Contact" />
          <Link to="/gallery/" children="Gallery" />
          <Link to="/terms/" children="Terms & Conditions" />
          <Link to="/privacy/" children="Privacy Policy" />
          <Link to="/refund" children="Refund and Cancellation Policy" />
        </Pages>
      </Box>
      <Box ml={-3}>
        <iframe
          title="CSFacebook"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcodingsastra%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="340"
          height="480"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </Box>
    </Columns>
    <Container>
      <BottomLine mt={3}>
        <Text fontSize={1} mt={2} color="muted" align="center">
          &copy; 2022,{' '}
          <strong>
            <Link to="/">Coding Sastra</Link>
          </strong>
          {','}
          DBA Ecorfy Technologies Pvt Ltd, All rights reserved.
        </Text>
      </BottomLine>
    </Container>
  </Base>
)

export default Footer
