import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box, Container, Flex, Icon, cx, theme } from '@hackclub/design-system'
import Flag from 'components/Flag'
import { Link, graphql, useStaticQuery } from 'gatsby'

import ScrollLock from 'react-scrolllock'
import {
  DropdownContainer,
  DropdownMenu,
  DropdownMenuOption
} from 'components/Dropdown'
const rgbaBgColor = (props, opacity) =>
  `rgba(
    ${props.bgColor[0]},
    ${props.bgColor[1]},
    ${props.bgColor[2]},
    ${opacity}
  )`

const Root = styled(Box.withComponent('header'))`
  ${props =>
    !props.unfixed &&
    css`
      position: absolute;
      top: 0;
    `};
  width: 100%;
  z-index: 1000;
  ${props =>
    (props.scrolled || props.toggled || props.fixed) &&
    css`
      position: fixed;
      background-color: ${rgbaBgColor(props, 0.96875)};
      border-bottom: 1px solid rgba(48, 48, 48, 0.125);
      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background-color: ${props.transparent
          ? 'transparent'
          : rgbaBgColor(props, 0.75)};
        ${props.dark
          ? css`
              -webkit-backdrop-filter: saturate(90%) blur(20px);
            `
          : css`
              -webkit-backdrop-filter: saturate(180%) blur(20px);
            `};
      }
      ${theme.mediaQueries.reduceTransparency} {
        -webkit-backdrop-filter: auto !important;
      }
    `} @media print {
    display: none;
  }
`

export const Content = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: ${theme.space[3]}px;
  ${theme.mediaQueries.md} {
    padding: 0 ${theme.space[4]}px;
  }
`

const hoverColor = name =>
  ({
    white: 'smoke',
    smoke: 'muted',
    muted: 'slate',
    slate: 'black',
    black: 'slate',
    primary: 'error'
  }[name] || 'black')

const NavBar = styled(Box.withComponent('nav'))`
  display: none;
  a {
    color: ${props => cx(props.color)};
    margin-left: ${theme.space[2]}px;
    padding: ${theme.space[2]}px;
    text-decoration: none;
  }
  ${props =>
    props.isMobile
      ? css`
          display: ${props.toggled ? 'flex' : 'none'};
          flex-direction: column;
          overflow-y: auto;
          text-align: left;
          height: 100vh;
          a {
            color: ${props.dark ? 'white' : 'black'};
            padding: 0;
            margin: 0 auto;
            height: 64px;
            line-height: 64px;
            font-weight: bold;
            width: 100%;
            max-width: 18rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(48, 48, 48, 0.125);
            }
            @media screen and (max-width: 22em) {
              max-width: 16rem;
            }
          }
        `
      : css`
          @media (min-width: 56em) {
            display: flex;
            position: absolute;
            left: 49%;
            transform: translateX(-50%);
          }
          @media (max-width: 1250px) {
            left: 44%;
          }
          @media (max-width: 1109px) {
            left: 55%;
          }
          a {
            font-size: ${theme.fontSizes[1]}px;
            text-transform: uppercase;
            &:hover {
              color: ${theme.cx(hoverColor(props.color))};
            }
          }
        `};
`
const link = {
  color: 'black'
}

const Navigation = props => {
  const data = useStaticQuery(graphql`
    query allPrograms {
      allContentfulProgram(sort: { fields: order }) {
        nodes {
          id
          title
          code
        }
      }
    }
  `)

  let Programs = data.allContentfulProgram.nodes

  return (
    <NavBar role="navigation" {...props}>
      <DropdownContainer>
        Programs <Icon size={17} glyph="down-caret" color="#606e77" />
        <DropdownMenu width={400}>
          {Programs.map(program => {
            return (
              <Link
                to={`/program/${program.code}/`}
                key={program.id}
                style={link}
              >
                <DropdownMenuOption>{program.title}</DropdownMenuOption>
              </Link>
            )
          })}
        </DropdownMenu>
      </DropdownContainer>
      <Link to="/workshops" children="Workshops" />
      <Link to="/careers" children="Careers" />
      <Link to="/corporate/" children="Corporate" />
      <DropdownContainer>
        More <Icon size={17} glyph="down-caret" color="#606e77" />
        <DropdownMenu align="center" width={200}>
         <Link to="/contact" style={link}>
            <DropdownMenuOption>Contact</DropdownMenuOption>
          </Link>
        </DropdownMenu>
      </DropdownContainer>
    </NavBar>
  )
}
const ToggleContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 64px;
  min-height: 44px;
  cursor: pointer;
  user-select: none;
  margin-left: auto;
  @media (min-width: 56em) {
    display: none;
  }
`

class Header extends Component {
  state = {
    scrolled: false,
    toggled: false
  }

  static defaultProps = {
    dark: false,
    color: 'white'
  }

  static propTypes = {
    color: PropTypes.string,
    bgColor: PropTypes.arrayOf(PropTypes.number),
    dark: PropTypes.bool,
    transparent: PropTypes.bool,
    fixed: PropTypes.bool,
    unfixed: PropTypes.bool
  }

  componentDidMount() {
    this.bindScroll(true)
    if (typeof window !== 'undefined') {
      const mobileQuery = window.matchMedia('(max-width: 48em)')
      mobileQuery.addListener(() => {
        this.setState({ toggled: false })
      })
    }
  }

  componentWillUnmount = () => {
    this.bindScroll(false)
  }

  bindScroll = add => {
    if (typeof window !== 'undefined' && !this.props.unfixed) {
      window[add ? 'addEventListener' : 'removeEventListener'](
        'scroll',
        this.onScroll
      )
    }
  }

  onScroll = () => {
    const newState = window.scrollY >= 16
    const { scrolled: oldState } = this.state

    if (newState !== oldState) {
      this.setState({
        scrolled: newState
      })
    }
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      toggled: !state.toggled
    }))
  }

  render() {
    const { color, dark, fixed, bgColor, ...props } = this.props
    const { scrolled, toggled } = this.state
    const baseColor = dark
      ? color || 'white'
      : color === 'white' && scrolled
      ? 'black'
      : color
    const toggleColor = dark
      ? color || 'snow'
      : toggled || (color === 'white' && scrolled)
      ? 'slate'
      : color

    return (
      <Root
        {...props}
        fixed={fixed}
        scrolled={scrolled}
        toggled={toggled}
        dark={dark}
        bgColor={bgColor || (dark ? [32, 34, 36] : [255, 255, 255])}
      >
        <Content>
          <Flag scrolled={scrolled || fixed} dark={dark} />
          <Navigation color={baseColor} dark={dark} />
          <ToggleContainer color={toggleColor} onClick={this.handleToggleMenu}>
            <Icon glyph={toggled ? 'view-close' : 'menu'} toggled={toggled} />
          </ToggleContainer>
        </Content>
        <Navigation isMobile toggled={toggled} color={baseColor} dark={dark} />
        {toggled && <ScrollLock />}
      </Root>
    )
  }
}

export default Header
