import { Box, Flex, Card, theme } from '@hackclub/design-system'
import styled, { css } from 'styled-components'

export const DropdownContainer = styled(Box)`
  position: relative;
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  padding: 11px 0px 0px 14px;
  font-size: 17px;
  ${props =>
    props.isMobile
      ? css`
          display: ${props.toggled ? 'flex' : 'none'};
          text-align: left;
        `
      : css`
          @media (max-width: 895px) {
            width: 77vw;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: capitalize;
            font-size: 19px;
            > svg {
              margin-left: 4px;
            }
          }
          @media (max-width: 769px) {
            width: 73vw;
          }
          @media (max-width: 426px) {
            width: 54vw;
          }
          @media (max-width: 376px) {
            width: 44vw;
          }
          @media (max-width: 321px) {
            width: 40vw;
          }
        `}
  &:hover {
    transition: 0.5s;
  }
  &:hover > svg {
    transform: rotate(180deg);
    transition: 0.5s;
  }
  > svg {
    margin: 3px 0px 0px 4px;
    color: #606e77;
  }
  &:hover > div {
    display: block;
    animation: 0.5s ${theme.transition};
    opacity: 1;
  }
`

export const DropdownMenu = styled(Card.withComponent(Flex))`
  display: none;
  opacity: 0;
  flex-direction: column;
  align-items: stretch;
  padding: ${theme.space[0]}px 0;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.boxShadows[2]};
  width: ${props => props.w || props.width || '256px'};
  max-width: 95vw;
  z-index: 10;
  position: absolute;
  left: 10px;
  top: 44px;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  ${props =>
    props.isMobile
      ? css`
          text-align: left;
        `
      : css`
          @media (max-width: 769px) {
            margin-left: 50px;
          }
          @media (max-width: 529px) {
            margin-left: 5px;
          }
        `}
`

export const DropdownMenuOption = styled(Box)`
  padding: 5px 0px 5px 20px;
  font-size: 19px;
  cursor: pointer;
  width: 100%;
  transition: 0.5s;
  text-transform: capitalize;
  &:hover > a {
    color: #606e77 !important;
  }
  ${props =>
    props.active &&
    css`
      background-color: ${theme.colors.smoke};
      font-weight: ${theme.bold};
      transition: 0.5s;
    `};
  &:hover {
    transition: ${theme.transition} background-color;
    background-color: ${theme.colors.blue[0]};
    color: #606e77 !important;
  }
`
